import './bootstrap';
import '@css/var.css'; // Global
import '@scss/demo-swingu/app.scss';

import { createApp, h } from 'vue';
import { Head, Link, createInertiaApp } from '@inertiajs/inertia-vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import Layout from '@components/web-swingu/layout.vue';
import { InertiaProgress } from '@inertiajs/progress';

// web.swingu
createInertiaApp({
    //resolve: (name) => resolvePageComponent(`./components/demo-swingu/${name}.vue`, import.meta.glob('./components/**/*.vue')),
    resolve: name => {
        const page = resolvePageComponent(
            `./components/web-swingu/${name}.vue`,
            import.meta.glob('./components/**/*.vue')
        );

        // Add default layout file to all pages
        page.then((module) => {
            module.default.layout = module.default.layout || Layout;
        });
        page.layout = page.layout || Layout;

        return page;
    },
    setup({ el, App, props, plugin }) {
        createApp({ render: () => h(App, props) })
            .use(plugin)
            .component('Head', Head)
            .component('InertiaLink', Link)
            .mount(el)
    },
});

InertiaProgress.init({
    color: getComputedStyle(document.documentElement).getPropertyValue('--page-status-indicator'),
    showSpinner: true
});
